import React, {useEffect, useState} from 'react';

function ConnectShopUrl() {
    let [shopUrl, setShopUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const regex = /^(https?:\/\/)?([a-z0-9-]+\.)[a-z0-9]*\/?\.?[a-z0-9-]+$/;

    // input handle change
    const handleChange = (e) => {
        shopUrl = e.target.value;
        setShopUrl(e.target.value);

        if (!regex.test(shopUrl) && shopUrl.length > 0) {
            setErrorMessage('Please enter a valid shopify URL.');
        } else {
            setErrorMessage('');
        }
    }

    useEffect(() => {
        const apiUrl = '/dashboard'; // Replace with the API URL

// Define the request options
        const requestOptions = {
            method: 'GET', // Replace with the HTTP method you want (e.g., 'POST', 'PUT', 'DELETE')
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', // Modify headers as needed
                // Add any other headers here
            },
            // body: JSON.stringify({ key: 'value' }), // Include a request body if needed (e.g., for POST or PUT requests)
        };

// Send the HTTP request
        fetch(apiUrl, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the response body as JSON
            })
            .then(data => {
                // Handle the API response data
                console.log(data);
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error:', error);
            });
    }, []);

    const btnState = (errorMessage !== '' || shopUrl.length < 1);

    return (
        <>
            <form className={"mx-auto mb-3"} method={'GET'} action={'/auth/shopify/begin'}>
                <label>Login with Shopify Shop Url</label>
                <div className={"flex"}>
                    <input
                        name={'shop'}
                        value={shopUrl}
                        type={"text"}
                        className={'w-full py-3 px-2 border-r-0 rounded-r-none border-2 ' + (errorMessage && "border-red-600")}
                        placeholder={"Enter Shop URL"}
                        onChange={handleChange}
                        autoFocus={true} />
                    <button type={"submit"} className={"w-28 bg-green-500 border-l-0 rounded-l-none"} disabled={btnState}>Install App</button>
                </div>
                {errorMessage && <div className={"text-red-600 font-medium"}>
                    {errorMessage}
                </div>}
            </form>
            <p className={"text-center mb-10"}>Install now and Generate <strong>2500</strong> AI words free. <br /><span className={"font-bold text-green-600"}>No credit card required</span> until you pay.</p>
        </>
    );
}

import ReactDOM from 'react-dom/client';

ReactDOM.createRoot(document.getElementById('connectShopUrl')).render(
    <ConnectShopUrl />
);
